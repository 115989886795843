export const expertiseCards = [
  {
    image: "card 1.jpg",
    heading: "Our Consultants",
    headingLines: 1,
    slug: "consultants",
    description: `Our consultants have been recruited from experienced practitioners within the security industry and bring...`,
    fullText: `Our consultants have been recruited from experienced practitioners within the security industry and bring with them experience in varied ‘specialist’ security fields allowing IJA to deploy consultants with appropriate and applicable skills to each client.

    Our in-house consultants are also strongly supported by retained Associates who have dedicated skills and experience covering the more esoteric and technical design areas of security and associated specialist fields.`
  },
  {
    image: "card 2.jpg",
    heading: "Art & Museum Security Specialists",
    headingLines: 2,
    slug: "art-museum",
    description: `IJA are members of the International Council of Museums (ICOM) and have...`,
    fullText: `IJA are members of the International Council of Museums (ICOM) and have advised many leading international Museums, Art Dealers and Galleries on security, risk and exhibition design. Key clients include The British Museum, The Natural History Museum, The Wallace Collection, The National Museum of Norway, Chatsworth House, The Courtauld Institute of Art, Kelvingrove Museum, Christie’s and Sotheby's to name a few.

    IJA’s Principal Consultant is also an appointed Consultant to Arts Council England (ACE) and has reviewed security measures at more than 100 venues across the UK. The role encompasses assessing security arrangements for artworks that are to be borrowed or where renewal of loan items is being sought under the Government Indemnity Scheme (GIS), as well as advising on ACE / Museum accreditation.`
  },
  {
    image: "card 3.jpg",
    heading: "UKAS Technical Experts",
    headingLines: 1,
    slug: "UKAS",
    description: `IJA Consultants are appointed Technical Assessors / Experts on behalf of the United Kingdom Accreditation...`,
    fullText: `IJA Consultants are appointed Technical Assessors / Experts on behalf of the United Kingdom Accreditation Service (UKAS). As Assessors, our remit includes responsibility for assessing the technical capability of certification bodies such as the NSI and SSAIB to certify companies offering security services or installing security equipment throughout the UK and the Republic of Ireland.`
  },
  {
    image: "card 4.jpg",
    heading: "SABRE Risk Assessment Assessors",
    headingLines: 2,
    slug: "SABRE",
    description: `SABRE is a security risk assessment and certification scheme endorsed by the...`,
    fullText: `SABRE is a security risk assessment and certification scheme delivered by BRE Global and accredited by the Loss Prevention Certification Board for new and existing buildings and built infrastructure assets. IJA are a licenced Company and are approved SABRE Registered Assessors. Our Senior Consultant is also a Registered Professional in the categories of SQSS, Threat, Risk and Strategy.`
  },
  {
    image: "card 5a.jpg",
    heading: "Affiliations",
    headingLines: 1,
    slug: "affiliations",
    description: `IJA passionately supports and endorses British Institutions, promoting security initiatives and best practice. In addition...`,
    fullText: `With its roots firmly in the private security industry, IJA has and continues to passionately support and endorse British Institutions promoting security initiatives and best practice. In addition, we maintain close contact with security equipment manufacturers and suppliers both in the UK and overseas. This gives us a unique insight into current advances in security technology, commercial trends and developments, and future legal obligations.

    Appointments previously held by IJA include:
    ​ • ​Founding Director and Fellow of The Security Institute
    ​ • ​Board Member and Marketing Director of the Association of Security Consultants
    ​ • ​Director and Chair of the Security Consultancies Section for the British Security Industry Association
    ​ • ​Chair of the Security Committee for the British Institute of Facilities Management`
  },
  {
    image: "card 6.jpg",
    heading: "Awards",
    headingLines: 1,
    slug: "awards",
    description: `IJA have been recognised by a range of industry organisations and won a number of major awards. IJA...`,
    fullText: `IJA have been recognised by a range of industry organisations and won a number of major awards:
     ​• ​Winner – Security Consultant of the Year 2020 – Outstanding Security Performance Awards (OSPAS) UK.​
     • ​Winner - Expert Witness Awards 2019.
     ​• ​Finalist - Security Excellence Awards 2014 – 2019.`
  },
];

export const servicesCards = [
  {
    image: "card 1.jpg",
    heading: "Security Reviews & Audits",
    headingLines: 1,
    slug: "security-reviews-and-audits",
    description: `Security reviews typically cover core areas such as physical, electronic and security measures or can, as appropriate cover the full spectrum of security measures deployed.`,
    fullText: `Security reviews typically cover core areas such as physical, electronic and security measures or can, as appropriate cover the full spectrum of security measures deployed. At the very least this usually includes the evaluation and testing of security systems and other technical measures, assessment of security guarding performance levels and a review of security strategy, policy and operational and emergency procedures. Each review will assess security measures in accordance with identified security threat and risks and against the business / client needs as it is appreciated that security measures proposed need to complement and not hinder ongoing operations.`
  },
  {
    image: "card 2a.jpg",
    heading: "Threat & Risk Assessments",
    headingLines: 1,
    slug: "threat-and-risk-assessments",
    description: `IJA have significant experience in identifying and evaluating threat and risk profiles across...`,
    fullText: `IJA have significant experience in identifying and evaluating threat and risk profiles across a wide range of public and private sectors. With every assessment undertaken, clients are advised on what controls to put in place in order to minimise identified risk as well as the associated consequences (impact) of business interruption resulting from the loss of facilities, information, supporting services and personnel.

    For those clients seeking additional qualification in line with BREEAM or SABRE accreditation, as qualified technical Assessors in Security Strategy and Design, IJA consultants can also advise or review threat and risk to help you towards obtaining SABRE certification.`
  },
  {
    image: "card 3.jpg",
    heading: "Security Design",
    headingLines: 1,
    slug: "security-design",
    description: `One of our driving policies is that security is an integral part of the design of any building, area or site and as such must...`,
    fullText: `One of our driving policies is that security is an integral part of the design of any building, area or site and as such must reflect and support the primary design concepts. To this end we typically like to work as an integral part of the design, planning and implementation team from the outset of the RIBA process. Our consultants can plan and design security systems with full integration of alarms, CCTV, access control and central monitoring facilities. Considerable care is taken to ensure that solutions address the differing risks and cultures of each client organisation. The service frequently includes project management from conceptual design through to system commissioning.`
  },
  {
    image: "card 4.jpg",
    heading: "Interim Security Management",
    headingLines: 1,
    slug: "interim-security-management",
    description: `Key requirements of the role vary depending on client expectations but can...`,
    fullText: `Key requirements of the role vary depending on client expectations but can typically include overseeing security operations; rationalising / restructuring current security operations; developing company wide security policies and procedures; implementing a security risk assessment and review process; project managing security system upgrades or implementing recommendations as identified from a security review.`
  },
  {
    image: "card 5.jpg",
    heading: "Strategy, Policy, Procedures & Manuals",
    headingLines: 2,
    slug: "strategy-policy-procedures-and-manuals",
    description: `IJA has extensive experience in assisting clients with the development of their core...`,
    fullText: `IJA has extensive experience in assisting clients with the development of their core strategy, policy and procedural documents to ensure a carefully planned and consistent approach to implementing security measures.`
  },
  {
    image: "card 6.jpg",
    heading: "Security Tender Procurement",
    headingLines: 1,
    slug: "security-tender-procurement",
    description: `IJA consultants are highly experienced in all aspects of security system and security...`,
    fullText: `IJA consultants are highly experienced in all aspects of security system and security services procurement and can assist in the preparation of all necessary documentation including drafting tender specifications and proposed contractual terms and performance management requirements, as well as project managing all other stages of the procurement process.

    Our consultants can advise on who should be invited to express an interest in tendering; develop the pre-qualification questionnaire and consider responses in order to draw up a short list; manage briefing sessions for the short list, and provide expert advice in the assessment of their submissions.`
  },
  {
    image: "card 7.jpg",
    heading: "Contract Performance",
    headingLines: 1,
    slug: "contract-performance",
    description: `Likewise clients sometimes feel that a particular security contract is not going as well as they would like, or simply...`,
    fullText: `Clients sometimes feel that a particular security contract is not going as well as they would like, or simply want an independent appraisal of performance. IJA’s comprehensive audit service considers whether sufficient controls, such as key performance indicators, are in place; assesses all aspects of contract performance including assessment against British Standard and industry best practice, and suggests ways of adding value as well as providing constructive feedback to assist both client and supplier.`
  },
  {
    image: "card 8.jpg",
    heading: "Expert Witness & Litigation Support",
    headingLines: 2,
    slug: "expert-witness-and-litigation-support",
    description: `IJA undertakes expert witness work on behalf of clients in cases of litigation involving...`,
    fullText: `IJA undertakes expert witness work on behalf of clients in cases of litigation involving security services and related issues and in 2019 were recognised by the Expert Witness Awards. Typical instructions include reviewing evidence and preparing detailed reports in response to legal claims and, when necessary, attending court to provide expert opinion.`
  },
  {
    image: "card 9.jpg",
    heading: "SABRE / BREEAM Assessment",
    headingLines: 1,
    slug: "sabre-and-breeam-assessment",
    description: `SABRE - developed by BRE Global, SABRE is an independent assessment and...`,
    fullText: `SABRE
     ​• Developed by BRE Global, SABRE is an independent assessment and certification scheme for new and existing buildings, infrastructure assets and managed space. The scheme, now recognised by many Council planning departments provides a framework that can be used during the design, construction and subsequent operation of facilities to demonstrate an organisation’s commitment to security, evidence value for money, attract security-minded tenants, and much more. Successful assessments result in a SABRE Rating ranging from ‘Acceptable’ (1 star) to ‘Outstanding’ (5 stars). IJA are registered to carry out SABRE assessments and one of our Senior Consultants is also a registered Security Professional.
      
     BREEAM – Security Needs Assessment (SNA)
       ​• The Building Research Establishment Environmental Assessment Method (BREEAM), administered by Britain’s Building Research Establishment (BRE), is the world's leading recognised design and assessment scheme for sustainable buildings.
       ​• In order to achieve a successful BREEAM rating, a building or asset needs to incorporate various design criteria that demonstrates both a commitment and efficient approach to various sustainability principles covering areas such as energy consumption, use of renewable materials, recycling, environmental and security management etc.
       ​• IJA’s team of Suitably Qualified Security Specialists (SQSS) can assist clients seeking BREEAM accreditation by carrying out a Security Needs Assessment (SNA) for New Construction resulting in potential additional BREEAM credit being awarded if evidence can be submitted to show that recommendations proposed by the SQSS were incorporated into the scheme in full.`
  },
];

export const homeCarouselSlides = [
  {
    image: "OSPA.png",
    caption: "IJA awarded Security Consultant of the Year 2020",
    text: `“IJA has been awarded an OSPA for its commitment to sharing best practices and the ability to provide effective security management for a range of high-profile clients. IJA has been described by clients as ‘outstanding’, ‘valuable’ and ‘trustworthy’. They have provided security advice both overseas and within the UK, bringing experience and expertise from a range of different sectors.”`,
    creditName: "Judges’ Comments",
    creditOrganisation: "Outstanding Security Performance Awards (OSPAs)"
  },
  {
    image: "Les Miz.jpg",
    caption: "Delfont Mackintosh Theatres",
    text: `IJA have been commissioned to develop the security strategy and systems design for a number of Theatres in the heart of London’s West End including a major refurbishment of the newly re-named Sondheim Theatre home to the world's longest running, critically acclaimed musical - Les Misérables.`
  },
  {
    image: "SABRE.jpg",
    caption: "SABRE",
    text: `IJA is a SABRE Licensed Company (SLC-026) and our SABRE Registered Assessors can provide a number of technical services to support the assessment process. We can also assist clients seeking to gain additional SABRE award credits towards BREEAM certification for new construction projects. <a href="/contact" class="text-primary-500 font-medium">Contact us</a> to see how we can best assist you in achieving SABRE certification.`,
    fitContain: true
  }
];

export const clientsTestimonials = [
  {
    testimonial: "The combination of IJA’s extensive knowledge of the security industry, ability to apply sound practical solutions to our very specific needs, and their commercial mindset and insights have made them invaluable to us.",
    title: "Deputy Head of Property and Projects",
    organisation: "Delfont Mackintosh Theatres"
  },
  {
    testimonial: "We have been engaging with IJA for a number of years now and their technical expertise has been invaluable in advising the Museum on a whole range of security issues.",
    title: "Chief Operating Officer",
    organisation: "The Wallace Collection"
  },
  {
    testimonial: "The service was very responsive when I needed a quick turnaround.",
    title: "Senior Lawyer",
    organisation: "Shook Hardy and Bacon"
  },
  {
    testimonial: "IJA’s support has been outstanding.",
    title: "National Security Adviser",
    organisation: "Arts Council England"
  },
  {
    testimonial: "I appreciate the value of getting independent security advice and often refer to IJA when required to assist with a particular technical issue or to seek guidance in assessing security risk or implementing strategic goals and objectives.",
    title: "Head of Security",
    organisation: "Coventry Building Society"
  },
  {
    testimonial: "IJA were quick to understand our key requirements and parameters in which we operate as well as provide us with both sound advice and clear practical guidance as to what options we should consider when addressing future security needs.",
    title: "Global Director of Security",
    organisation: "Christie's"
  },
  {
    testimonial: "The service was exemplary, and no improvement required.",
    title: "Security Manager",
    organisation: "Manchester City Council"
  },
  {
    testimonial: "IJA has both our respect and trust and we would highly recommend them.",
    title: "Director",
    organisation: "Campus Support Services, University of East Anglia"
  },
  {
    testimonial: "I am happy to say that the BBC enjoyed a long standing and productive relationship with IJA for a significant number of years. They provided a wide range of security consultancy services that met our strategic and operational needs.",
    title: "Head of Corporate Security and Investigations",
    organisation: "BBC (retired)"
  }
];

export const clientsLogos = ["arts council england.png", "bath spa university.png", "christies.png", "coventry building society.png", "delfont mackintosh theatres.png", "university east anglia.png"];

export const projects = [
  {
    title: "The British Museum",
    description: "Prepared museum's security strategy & security systems design for the World Conservation & Exhibition Centre",
    image: "british museum.jpg"
  },
  {
    title: "Olympic Development Authority",
    description: "Appointed Security Consultants to 2012 Olympic Games",
    image: "olympic.jpg"
  },
  {
    title: "BBC",
    description: "Retained as advisers for over 20 years, prepared the Broadcaster’s Security Codes of Practice",
    image: "bbc.jpg"
  },
  {
    title: "Manchester City Council",
    description: "Advised & project managed the tender management of largest council security services tender",
    image: "manchester.jpg"
  },
  {
    title: "Central European University",
    description: "Security Strategy and systems design for multi-million Euro redevelopment of Budapest campus",
    image: "ceu.jpg"
  },
  {
    title: "New Labour",
    description: "Security advisers to Tony Blair’s successful General Election campaign",
    image: "labour.jpg"
  },
  {
    title: "National Bank Of Kuwait",
    description: "Security reviews and advice across network of branches in Kuwait and London",
    image: "kuwait.jpg"
  },
  {
    title: "Scottish Parliament",
    description: "Security advisers to Parliament on several projects including manned guarding",
    image: "scottish parliament.jpg"
  },
  {
    title: "Natural History Museum",
    description: "Security design for the largest collection of diamonds ever to be exhibited",
    image: "natural history.jpg"
  },
  {
    title: "Belfast City Council",
    description: "Organisational review of security operations",
    image: "belfast.jpg"
  },
  {
    title: "The Courtauld Institute of Art",
    description: "Security strategy and design for multi-million pound refurbishment of Somerset House location",
    image: "courtauld institute of art.jpg"
  },
  {
    title: "The Royal Household",
    description: "Appointed by the Royal Household to advise on security matters",
    image: "royals.jpg"
  },
  {
    title: "American National Power",
    description: "Security review of utility sites in Texas and Louisiana",
    image: "american power.jpg"
  },
  {
    title: "Sotheby’s New York",
    description: "Art security advice and exhibition design for jaw dropping major exhibition at Sotheby’s newly refurbished galleries",
    image: "sothebys.jpg"
  }
];
