import React, { useState } from 'react';
import useScrollPosition from '@react-hook/window-scroll';

const Nav = () => {

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const scrollPosition = useScrollPosition();

  const routes = [
    {
      label: "Home",
      route: "/"
    },
    {
      label: "Services",
      route: "/services"
    },
    {
      label: "Expertise",
      route: "/expertise"
    },
    {
      label: "Clients",
      route: "/clients"
    },
    {
      label: "Projects",
      route: "/projects"
    },
    {
      label: "Contact",
      route: "/contact"
    }
  ];

  return (
    <nav className={`z-30 text-white fixed top-0 left-0 w-full h-20 px-8 transition-all duration-200 ease-linear ${scrollPosition > 10 ? 'bg-primary-500 shadow-xl' : ''}`}>
      <div className="flex justify-between container-wide">
        <a href="/"><img src="/img/logo.svg" className="h-12 mt-4" alt="" /></a>
        <ul className="hidden md:flex pt-7 text-lg desktop-nav">
          {routes.map((x, i) =>
            <li key={i}>
              <a className="text-shadow-lg" href={x.route}>{x.label}</a>
            </li>
          )}
        </ul>
        <svg onClick={() => setMobileNavOpen(true)} className="cursor-pointer block md:hidden fill-current h-7 w-7 mt-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/></svg>
        <ul className={`fixed md:hidden right-0 top-0 text-lg text-right mobile-nav h-screen max-w-2xs select-none transition-all duration-200 bg-gray-600 ${mobileNavOpen ? 'mr-0' : '-mr-40'}`}>
          <li className="cursor-pointer" onClick={() => setMobileNavOpen(false)}>
            <svg className="ml-auto block fill-current mt-4 mb-2 h-7 w-7" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
          </li>
          {routes.map((x, i) =>
            <li key={i}>
              <a href={x.route}>{x.label}</a>
            </li>
          )}
        </ul>
      </div>
    </nav>
  )
};

export default Nav;
