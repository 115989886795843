import React, { useState } from 'react';
import { clientsTestimonials, clientsLogos } from '../content';

const Clients = () => {

  return (
    <>
      {/* Hero */}
      <div>
        <div className="relative w-full h-88 pt-50 px-8">
          {/*<img alt="" src="/img/home.png" className="top-0 left-0 w-full h-160 object-cover absolute" />*/}
          <img alt="" src="/img/hero.jpg" className="top-0 left-0 w-full h-88 object-cover absolute" />
          <div className="flex flex-col justify-center h-full text-center">
            <div className="z-10 container-wide w-full text-white">
              <h1 className="text-shadow-lg">Clients</h1>
            </div>
          </div>
        </div>
      </div>
      {/* End hero */}

      {/* Intro */}
      <div className="pt-8">
        <div className="container px-4">
          <p>
            IJA believe it is important for security not to be considered in isolation with other professions but to work together in partnership. Our aim - through sound practical advice - is to demonstrate the significant value that effective security management can bring to business best practice.
          </p>
          <p>
            Notable appointments over the years have included being retained as official Security Advisers to The British Royal Family, the BBC, The British Museum, The National Museum of Norway, British Airways, Deloitte, Sotheby’s New York, Scottish Parliament, The Conservative and Labour Parties, and the  London Olympic Games to name a few.
          </p>
          <p>
            There is probably no better way to demonstrate our reputation within the security industry than by detailing some of the feedback we have received from our clients and supporters...
          </p>
        </div>
      </div>
      {/* End Intro */}

      {/* Clients
      <div className="pb-16 pt-8">
        <div className="container px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {clientsLogos.map((logo, i) =>
            <div key={i} className="bg-gray-300 h-32 p-4">
              <img src={"/img/clients/" + logo} className="object-contain w-full h-full" />
            </div>
          )}
        </div>
      </div>
      End Clients */}

      {/* Testimonials */}
      <div className="pb-8 mt-8">
        <div className="container px-4 grid grid-cols-1 md:grid-cols-2 gap-6">
          {clientsTestimonials.map((testimonial, i) =>
            <div key={i} className="bg-primary-500 h-full p-4">
              <div className="w-full text-white">
                <div className="relative">
                  <svg className="fill-current text-white w-3 h-3 absolute top-0 left-0 m-0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z"/></svg>
                  <p className="px-6 py-4">{testimonial.testimonial}</p>
                  <svg className="fill-current text-white w-3 h-3 transform rotate-180 absolute bottom-0 right-0 m-0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z"/></svg>
                </div>
                <div className="px-6 py-4 italic">
                  <p className="font-medium text-right mb-0">{testimonial.title}</p>
                  <p className="font-medium text-right mb-0 text-sm text-gray-400">{testimonial.organisation}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* End Testimonials */}

      {/* CTA */}
      <div className="pb-16">
        <div className="container px-4">
          <a className="pt-4 w-content mx-auto block" href="/projects"><button className="btn btn-primary">Find Out More</button></a>
        </div>
      </div>
      {/* End CTA */}
    </>
  )
};

export default Clients;
