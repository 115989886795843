import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './pages/Home';
import Services from './pages/Services';
import ServicesDetail from './pages/ServicesDetail';
import Expertise from './pages/Expertise';
import ExpertiseDetail from './pages/ExpertiseDetail';
import Clients from './pages/Clients';
import Projects from './pages/Projects';
import Contact from './pages/Contact';

import Nav from './components/Nav';
import Footer from './components/Footer';

function App() {

  const sharedProps = {};

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/services/:card" component={x => <>
            <Nav />
            <div className="content">
              <ServicesDetail {...x} {...sharedProps} />
            </div>
            <Footer />
          </>} exact />
          <Route path="/services" component={x => <>
            <Nav />
            <div className="content">
              <Services {...x} {...sharedProps} />
            </div>
            <Footer />
          </>} exact />
          <Route path="/expertise/:card" component={x => <>
            <Nav />
            <div className="content">
              <ExpertiseDetail {...x} {...sharedProps} />
            </div>
            <Footer />
          </>} exact />
          <Route path="/expertise" component={x => <>
            <Nav />
            <div className="content">
              <Expertise {...x} {...sharedProps} />
            </div>
            <Footer />
          </>} exact />
          <Route path="/clients" component={x => <>
            <Nav />
            <div className="content">
              <Clients {...x} {...sharedProps} />
            </div>
            <Footer />
          </>} exact />
          <Route path="/projects" component={x => <>
            <Nav />
            <div className="content">
              <Projects {...x} {...sharedProps} />
            </div>
            <Footer />
          </>} exact />
          <Route path="/contact" component={x => <>
            <Nav />
            <div className="content">
              <Contact {...x} {...sharedProps} />
            </div>
            <Footer />
          </>} exact />
          <Route path="/" component={x => <>
            <Nav />
            <div className="content">
              <Home {...x} {...sharedProps} />
            </div>
            <Footer />
          </>} exact />
          <Route path="/">
            <p>404 - page not found</p>
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
