import React, { useState } from 'react';
import { projects } from '../content';

const Projects = () => {

  return (
    <>
      {/* Hero */}
      <div>
        <div className="relative w-full h-88 pt-50 px-8">
          {/*<img alt="" src="/img/home.png" className="top-0 left-0 w-full h-160 object-cover absolute" />*/}
          <img alt="" src="/img/hero.jpg" className="top-0 left-0 w-full h-88 object-cover absolute" />
          <div className="flex flex-col justify-center h-full text-center">
            <div className="z-10 container-wide w-full text-white">
              <h1 className="text-shadow-lg">Projects</h1>
            </div>
          </div>
        </div>
      </div>
      {/* End hero */}

      {/* Services */}
      <div className="pt-12">
        <div className="container-wide px-4 grid grid-cols-1 lg:grid-cols-2 gap-0">
          {projects.map((project, i) =>
            <div key={i} className="project-card relative h-104 flex content-center items-center text-center overflow-hidden border border-gray-200">
              <img className="w-full h-full absolute top-0 left-0 object-cover z-10" alt="" src={"/img/projects/" + project.image} />
              <div className="z-20 absolute bottom-0 transition-all duration-100 top-0 mt-92 w-full bg-gray-50 h-12 text-white text-left">
                <p className="project-title text-sm sm:text-base pt-3 px-8 transition-all duration-100">
                  {project.title}
                </p>
                <p className="project-description text-sm sm:text-base pt-3 px-8 transition-all duration-100">
                  {project.description}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* End Services */}

      {/* CTA */}
      <div className="pb-12 pt-8">
        <div className="container-wide px-4">
          <a className="pt-4 w-content mx-auto block" href="/contact"><button className="btn btn-primary">Get in touch</button></a>
        </div>
      </div>
      {/* End CTA */}
    </>
  )
};

export default Projects;
