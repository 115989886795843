import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { homeCarouselSlides as carouselSlides } from '../content';

const Home = () => {

  const awardImages = ["Expert Witness.png", "OSPA.png", "Security & Fire.png"];

  return (
    <>
      {/* Hero */}
      <div>
        <div className="relative w-full h-160 pt-50 px-8">
          <img alt="" src="/img/hero.jpg" className="top-0 left-0 w-full h-160 object-cover absolute" />
          <div className="flex flex-col justify-center h-full ">
            <div className="z-10 container-wide w-full text-white">
              <h1 className="text-shadow-lg">Security and<br />Risk Advisers</h1>
              <h3 className="max-w-lg text-shadow-lg">IJA is an award winning independent security and risk management consultancy</h3>
              <a href="/services"><button className="btn btn-secondary mt-8">Find out more</button></a>
            </div>
          </div>
        </div>
      </div>
      {/* End hero */}

      {/* Awards */}
      <div className="bg-gray-200 py-8">
        <div className="flex justify-center">
          {awardImages.map(i => <img key={i} src={"/img/awards/" + i} alt="" className="h-10 md:h-16 lg:h-20 m-2 md:my-4 md:mx-6 object-contain" />)}
        </div>
      </div>
      {/* End Awards */}

      {/* About Us */}
      <div className="py-8">
        <div className="container px-4">
          <div className="section-heading">
            <h4>About us</h4>
            <hr />
          </div>
          <p>
            IJA (Ian Johnson Associates Limited) is a multi-award winning independent security and risk management consultancy practice with an established proven reputation in advising on all aspects of security threat and risk.
          </p>
          <p>
            The company has considerable experience of working in collaborative partnerships and has built long standing relationships with many leading organisations.
          </p>
        </div>
      </div>
      {/* End About Us */}

      {/* Carousel */}
      <div className="pt-8 pb-4 bg-gray-200">
        <div className="container px-4 pt-375">
          <Slider autoplay={true} lazyLoad={true} autoplaySpeed={15000} pauseOnDotsHover={true} pauseOnFocus={true} pauseOnHover={false} responsive={[
            {
              breakpoint: 800,
              settings: {
                dots: true,
                arrows: false
              }
            },
            {
              breakpoint: 9999,
              settings: {
                dots: true,
                arrows: true
              }
            }
          ]}>
            {carouselSlides.map((slide, i) =>
              <div key={i} className="md:px-12">
                <div className="w-full grid grid-cols-1 sm:grid-cols-3 gap-4">
                  <div className="col-span-2">
                    <div className="relative h-100 bg-white">
                      <img alt="" src={"/img/carousel/" + slide.image} className={`w-full h-104 ${slide.fitContain ? 'object-contain p-8' : 'object-cover'}`} />
                      {slide.caption &&
                        <div className="absolute bottom-0 left-0 w-full bg-gray-50 text-white">
                          <p className="text-sm sm:text-base pt-4 px-4">
                            {slide.caption}
                          </p>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="sm:text-right">
                    <p dangerouslySetInnerHTML={{__html: slide.text}} />
                    {(slide.creditName || slide.creditOrganisation) &&
                      <p className="font-medium">
                        {slide.creditName && <>
                          <span>{slide.creditName}</span>
                          <br />
                        </>}
                        {slide.creditOrganisation &&
                          <span className="text-sm text-gray-600">{slide.creditOrganisation}</span>
                        }
                      </p>
                    }
                  </div>
                </div>
              </div>
            )}
          </Slider>
          </div>
      </div>
      {/* End Carousel */}

      {/* CTA */}
      <div className="py-8">
        <div className="container px-4">
          <p>
            IJA works with companies in virtually every business sector and has used this cumulative experience to sharpen and refine the work we do with clients who face industry specific challenges.
          </p>
          <p>
            Find out more about the full range of services we offer.
          </p>
          <a className="pt-4 w-content mx-auto block" href="/services"><button className="btn btn-primary">Our Services</button></a>
        </div>
      </div>
      {/* End CTA */}
    </>
  )
};

export default Home;
