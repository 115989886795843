import React, { useState } from 'react';

const Contact = () => {

  return (
    <>
      {/* Hero */}
      <div>
        <div className="relative w-full h-88 pt-50 px-8">
          {/*<img alt="" src="/img/home.png" className="top-0 left-0 w-full h-160 object-cover absolute" />*/}
          <img alt="" src="/img/hero.jpg" className="top-0 left-0 w-full h-88 object-cover absolute" />
          <div className="flex flex-col justify-center h-full text-center">
            <div className="z-10 container-wide w-full text-white">
              <h1 className="text-shadow-lg">Contact</h1>
            </div>
          </div>
        </div>
      </div>
      {/* End hero */}

      {/* Intro */}
      <div className="pt-8">
        <div className="container px-4">
          <p>
            Find out how IJA can help with your security requirements. Get in touch and a member of our team will be in contact with you.
          </p>
        </div>
      </div>
      {/* End Intro */}

      {/* Contact */}
      <div>
        <div className="container px-4 pl-8 md:pl-12 lg:pl-16">
          <div className="mb-4">
            <a href="mailto:info@ija.co.uk"><svg className="text-primary-500 fill-current w-8 h-8 inline-block align-middle" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z"/></svg> <span className="align-middle ml-2">info@ija.co.uk</span></a>
          </div>
          <div className="mb-4">
            <a href="tel:+441252782664"><svg className="text-primary-500 fill-current w-8 h-8 inline-block align-middle" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg> <span className="align-middle ml-2">+44 (0) 1252 782664</span></a>
          </div>
          <div className="mb-12">
            <svg className="text-primary-500 fill-current w-8 h-8 inline-block align-middle" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 24h-23v-16h6v-8h11v12h6v12zm-12-5h-3v4h3v-4zm4 0h-3v4h3v-4zm6 0h-2v4h2v-4zm-17 0h-2v4h2v-4zm11-5h-2v2h2v-2zm-5 0h-2v2h2v-2zm11 0h-2v2h2v-2zm-17 0h-2v2h2v-2zm11-4h-2v2h2v-2zm-5 0h-2v2h2v-2zm-6 0h-2v2h2v-2zm11-4h-2v2h2v-2zm-5 0h-2v2h2v-2zm5-4h-2v2h2v-2zm-5 0h-2v2h2v-2z"/></svg> <span className="align-middle ml-2">IJA, Linden Place, Seale, Farnham, Surrey, GU10 1HS</span>
          </div>
        </div>
      </div>
      {/* End Contact */}
    </>
  )
};

export default Contact;
