import React from 'react';
import { expertiseCards as cards } from '../content';

const Expertise = () => {

  return (
    <>
      {/* Hero */}
      <div>
        <div className="relative w-full h-88 pt-50 px-8">
          {/*<img alt="" src="/img/home.png" className="top-0 left-0 w-full h-160 object-cover absolute" />*/}
          <img alt="" src="/img/hero.jpg" className="top-0 left-0 w-full h-88 object-cover absolute" />
          <div className="flex flex-col justify-center h-full text-center">
            <div className="z-10 container-wide w-full text-white">
              <h1 className="text-shadow-lg">Expertise</h1>
            </div>
          </div>
        </div>
      </div>
      {/* End hero */}

      {/* Intro */}
      <div className="pt-8">
        <div className="container px-4">
          <p>
            IJA (Ian Johnson Associates Limited) is recognised as one of the UK’s leading totally independent security consultancy practices with experience of working in almost every business sector and an established reputation in the disciplines of security and risk management. Our key objective is to help our clients minimise the risks to people, property and information.
            <br />
            <br />
            Our ability to provide a broad spectrum of security services allows us to offer consultancy that constantly reflects best practice, applies proven methodologies and introduces tried and tested technology and innovative approaches to our clients.
          </p>
        </div>
      </div>
      {/* End Intro */}

      {/* Grid */}
      <div className="pt-4">
        <div className="container px-4 grid grid-cols-1 sm:grid-cols-2 w-full md:grid-cols-2 lg:grid-cols-3 col-gap-6 row-gap-8">
          {cards.map((card, i) =>
            <div key={i} className="rounded overflow-hidden shadow-md hover:shadow-lg transform hover:-translate-y-5000 border border-gray-200 transition-all duration-100 cursor-pointer">
              <a href={"/expertise/" + card.slug} className="block">
                <img className="w-full h-48 object-cover border-b border-gray-200" src={"/img/expertise/" + card.image} alt="" />
                <div className="p-4">
                  <div className="font-bold text-lg text-primary-500 mb-2 leading-tight">{card.heading}</div>
                  <p className="text-gray-700 text-base h-18 md:h-16 lg:h-12">
                    {card.description}
                  </p>
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
      {/* End Grid */}

      {/* CTA */}
      <div className="py-8">
        <div className="container px-4">
          <a className="pt-4 w-content mx-auto block" href="/clients"><button className="btn btn-primary">Find Out More</button></a>
        </div>
      </div>
      {/* End CTA */}
    </>
  )
};

export default Expertise;
