import React from 'react';
import { expertiseCards as cards } from '../content';

const ExpertiseDetail = ({ match }) => {

  const thisCard = cards.find(x => x.slug === match.params.card);

  return (
    <>
      {/* Hero */}
      <div>
        <div className="relative w-full h-88 pt-50 px-8">
          <img alt="" src="/img/hero.jpg" className="top-0 left-0 w-full h-88 object-cover absolute" />
          <div className="flex flex-col justify-center h-full text-center">
            <div className="z-10 container-wide w-full text-white">
              <h1 className="text-shadow-lg">Expertise</h1>
            </div>
          </div>
        </div>
      </div>
      {/* End hero */}

      {/* Content */}
      <div className="py-8">
        <div className="container px-4">
          <a href="/expertise" className="inline-block mb-8">
            <button className="btn btn-secondary">
              <svg className="transform rotate-180 inline-block align-middle h-4 w-4 fill-current mr-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>
              <span className="align-middle mr-1">Back</span>
            </button>
          </a>
          <p className="font-bold text-lg text-primary-500 mb-2">
            {thisCard.heading}
          </p>
          <p className="whitespace-pre-line">
            {thisCard.fullText}
          </p>
        </div>
      </div>
      {/* End Content */}
    </>
  )
};

export default ExpertiseDetail;
