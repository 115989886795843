import React from 'react';

const Footer = () => {
  return (
    <div className="py-8 bg-primary-500">
      <div className="container px-4">
        <img alt="" src="/img/logo.svg" className="h-12" />
        <div className="text-white mt-4">
          <span className="w-24 inline-block">Tel:</span><br className="block md:hidden" /><span className="font-medium text-white">+44 (0) 1252 782664</span>
          <br />
          <div className="block md:hidden h-2" />
          <span className="w-24 inline-block">Email:</span><br className="block md:hidden" /><span className="font-medium">info@ija.co.uk</span>
          <div />
          <div className="block md:hidden h-2" />
          <span className="w-24 inline-block">Address:</span><br className="block md:hidden" /><span className="font-medium">IJA, Linden Place, Seale, Farnham, Surrey, GU10 1HS</span>
          <div className="block md:hidden h-2" />
        </div>
        <p className="mb-0 font-medium text-white">
          <svg className="fill-current h-3 w-3 align-middle mb-1 mr-1 inline-block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 15.781c-2.084 0-3.781-1.696-3.781-3.781s1.696-3.781 3.781-3.781c1.172 0 2.306.523 3.136 1.669l1.857-1.218c-1.281-1.826-3.133-2.67-4.993-2.67-3.308 0-6 2.692-6 6s2.691 6 6 6c1.881 0 3.724-.859 4.994-2.67l-1.857-1.218c-.828 1.14-1.959 1.669-3.137 1.669z"/></svg> Ian Johnson Associates Ltd. {new Date().getFullYear()}
        </p>
        <p className="mb-0 text-gray-400 font-medium">Website by <a href="https://bensommer.co.uk" target="__blank" className="no-underline text-gray-400">Ben Sommer</a></p>
      </div>
    </div>
  )
};

export default Footer;
